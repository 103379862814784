.card-width {
  width: 18rem;
}

.button-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 601px) {
  .card-width {
    width: 16rem;
  }
}

@media (max-width: 414px) {
  .card-width {
    width: 14rem;
    font-size: 0.7rem;
  }

  .text-center {
    font-size: 0.9rem;
  }
}
