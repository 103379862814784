@charset "utf-8"
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap')

// Bulma Variables
$primary: #206ebf
$info: #eef6ff
$footer-color: #206ebf
$family-primary: 'Source Sans Pro', 'Calibri', sans-serif

@import "../../node_modules/bulma/bulma.sass"

body
  overflow-x: hidden
